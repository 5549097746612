@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Golos+Text&display=swap");

.certificate {
  scrollbar-width: 2px; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  overflow-y: scroll; /* necessary for Safari */
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}


.invoice-details-cont {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 28px #00000005;
  border-radius: 5px;
  opacity: 1;
}

.summary-cont {
  border-collapse: collapse;
  width: 100%;
}

.summary-table {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.bg-field {
  outline: none;
  border: none;
}

.user-reg-cont {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 28px #00000005;
  border-radius: 16px;
  opacity: 1;
}

#reconTable thead tr th {
  background-color: rgba(59, 130, 246, 0.5);
  border: 2px solid black;
  padding: 10px;
  text-align: left;
  white-space: nowrap;
}

#reconTable tr td {
  border: 1px solid black;
  padding: 10px;
  white-space: nowrap;
}

#reconTable tr:nth-child(even) {
  background-color: rgba(59, 130, 246, 0.1);
}

.vehicle-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.vehicle-table table {
  table-layout: auto !important;
}

.proceed-btn:disabled {
  background-color: rgba(59, 130, 246, 0.5);
  color: gray;
}

@media print {
  .hidden-print {
    display: none !important;
  }
  .invoice-details {
    break-inside: avoid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-top: 30px;
  }
}

@media (min-width: 1024px) {
  .invoice-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    margin-top: 30px;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
    zoom: 100%;
    size: A4 portrait;
    overflow: hidden;
  }

  body {
    padding-top: 72px;
    padding-bottom: 72px;
    width: 210mm;
    height: 297mm;
  }
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important; /* Firefox 48 – 96 */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}
